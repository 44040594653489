
import { proxy } from "valtio";
import { create } from "zustand";

const useActiveCanvasStore = create((set) => ({
    canvas: null,
    stage: { width: 1200, height: 1200 },
    setCanvas: (val) => set({ canvas: val }),
    setStage: (val) => set({ stage: val }),
}));

const useSelectionStore = create((set) => ({
    selections: [],
    selection: null,
    setSelections: (val) => set({ selections: val }),
    setSelection: (val) => set({ selection: val }),
}));

const useFrameStore = create((set, get) => ({
    template: null,
    front: null,
    back: null,
    sleeve_left: null,
    sleeve_right: null,
    resetFrames: () => set({ template: null, front: null, back: null, sleeve_left: null, sleeve_right: null }),
    setFrames: (key, val) => set({ [key]: val }),
}));

const useInitItems = create((set) => ({
    initTemplate: null,
    initClipart: null,
    initPromptImage: null,
    initProduct: null,
    setInitProduct: (val) => set({ initProduct: val }),
    setInitTemplate: (val) => set({ initTemplate: val }),
    setInitClipart: (val) => set({ initClipart: val }),
    setInitPromptImage: (val) => set({ initPromptImage: val }),
    setInitItems: (val) => set(val)
}));

const useEditorStore = create((set, get) => ({
    leftsidebar: false,
    leftsidebar_mobile: false,
    rightsidebar: false,
    productsidebar: false,
    templateMode: true,
    predesignProductMode: false,
    activeSidebar: "designer",
    activeStageName: "template",
    leftsidebar_width: 500,
    predesignsModal: false,
    predesignsModalForce: false,
    productSelectModal: false,
    applyTemplateToProduct: false,
    applyClipartToProduct: false,
    selectedProductCategory: 'all',
    setPredesignProductMode: (val) => set({ predesignProductMode: val }),
    setBothState: (key, val) => set({ [key]: val }),
    setProductSelectModal: (val, forceSelect = false) => set({ productSelectModal: val, predesignsModalForce: forceSelect }),
    setPredesignsModal: (val) => set({ predesignsModal: val }),
    toggleLeftSidebar: () => set({ leftsidebar: !get().leftsidebar }),
    toggleLeftSidebarMobile: () => set({ leftsidebar_mobile: !get().leftsidebar_mobile }),
    toggleRightSidebar: () => set({ rightsidebar: !get().rightsidebar }),
    toggleProductSidebar: () => set({ productsidebar: !get().productsidebar }),
    toggleTemplateMode: () => set({ templateMode: !get().templateMode }),
    setToProductMode: (openLeft = false) => set({ leftsidebar: openLeft, rightsidebar: false, templateMode: false }),
    setToTemplateMode: (openLeft = true) => set({ leftsidebar: openLeft, rightsidebar: false, templateMode: true, activeStageName: "template" }),
    setActiveSidebar: (sidebar) => set({ leftsidebar: true, activeSidebar: sidebar }),
    setActiveStageName: (name) => set({ activeStageName: name }),
}));

const bothState = proxy({
    asAdmin: false,
    leftsidebar: false,
    leftsidebar_mobile: false,
    rightsidebar: false,
    productsidebar: false,
    templateMode: true,
    predesignProductMode: false,
    activeSidebar: "designer",
    activeStageName: "template",
    leftsidebar_width: 500,
    predesignsModal: false,
    predesignsModalForce: false,
    productSelectModal: false,
    applyTemplateToProduct: false,
    applyClipartToProduct: false,
    selectedProductCategory: 'all',
})

const bothAction = {
    setAsAdmin: (val) => (bothState.asAdmin = val),
    setPredesignProductMode: (val) => (bothState.predesignProductMode = val),
    setBothState: (key, val) => (bothState[key] = val),
    setProductSelectModal: (val, forceSelect = false) => {
        bothState.productSelectModal = val;
        bothState.predesignsModalForce = forceSelect;
    },
    setPredesignsModal: (val) => (bothState.predesignsModal = val),
    toggleLeftSidebar: () => (bothState.leftsidebar = !bothState.leftsidebar),
    toggleLeftSidebarMobile: () => (bothState.leftsidebar_mobile = !bothState.leftsidebar_mobile),
    toggleRightSidebar: () => (bothState.rightsidebar = !bothState.rightsidebar),
    toggleProductSidebar: () => (bothState.productsidebar = !bothState.productsidebar),
    toggleTemplateMode: () => (bothState.templateMode = !bothState.templateMode),
    setToProductMode: (openLeft = false) => {
        bothState.leftsidebar = openLeft;
        bothState.rightsidebar = false;
        bothState.templateMode = false;
    },
    setToTemplateMode: (openLeft = true) => {
        bothState.leftsidebar = openLeft;
        bothState.rightsidebar = false;
        bothState.templateMode = true;
        bothState.activeStageName = "template";
    },
    setActiveSidebar: (sidebar) => {
        bothState.leftsidebar = true;
        bothState.activeSidebar = sidebar;
    },
    setActiveStageName: (name) => {
        bothState.activeStageName = name;
    },
}

export {
    bothAction,
    bothState,
    useActiveCanvasStore,
    useFrameStore,
    useInitItems,
    useSelectionStore
};

